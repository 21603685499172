import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { type KioskSettings } from 'main/services/settings.service';

type State = KioskSettings;

type Action = {
  setSettings: (settings: KioskSettings) => void;
};

export const useSettingsStore = create<State & Action>()(
  immer((set) => ({
    tid: '',
    printerPort: '',
    rendererUrl: '',
    isOnlyForSureUser: false,
    setSettings: (settings: KioskSettings) =>
      set((state) => {
        state.tid = settings.tid;
        state.printerPort = settings.printerPort;
        state.rendererUrl = settings.rendererUrl;
        state.isOnlyForSureUser = settings.isOnlyForSureUser;
      }),
  })),
);
