var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Type } from 'class-transformer';
import { IsArray, IsBoolean, IsDateString, IsEnum, IsNumber, IsOptional, IsString, Validate, ValidatorConstraint, } from 'class-validator';
import { GetManyQueryDto } from '../../../classes/common/request/get-many-query.dto';
import { TransformBoolean } from '../../../decorators';
import { ReservationRoute, ReservationState, VehicleModelType } from '../../../enums';
import { PartialType } from '../../../utils';
export let IsReservationStateQuery = class IsReservationStateQuery {
    validate(value, args) {
        return __awaiter(this, void 0, void 0, function* () {
            return ((typeof value === 'string' &&
                (value === 'UNREVOKED' ||
                    Object.values(ReservationState).includes(value))) ||
                (Array.isArray(value) &&
                    value.every((item) => Object.values(ReservationState).includes(item))));
        });
    }
};
IsReservationStateQuery = __decorate([
    ValidatorConstraint()
], IsReservationStateQuery);
export class GetManyReservationsQueryDto extends PartialType(GetManyQueryDto) {
}
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "id", void 0);
__decorate([
    Validate(IsReservationStateQuery, {
        message: (args) => `state must be one of ${Object.values(ReservationState).join(', ')}`,
    }),
    IsOptional(),
    __metadata("design:type", Object)
], GetManyReservationsQueryDto.prototype, "state", void 0);
__decorate([
    IsEnum(ReservationRoute),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "route", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "fromCreatedAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "toCreatedAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "createdAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "fromPickUpAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "toPickUpAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "pickUpAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "fromDropOffAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "fromDropOffAtTime", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "toDropOffAt", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "dropOffAt", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "vendorId", void 0);
__decorate([
    IsArray(),
    Type(() => Number),
    IsNumber({}, { each: true }),
    IsOptional(),
    __metadata("design:type", Array)
], GetManyReservationsQueryDto.prototype, "vendorIds", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "vehicleModelId", void 0);
__decorate([
    IsEnum(VehicleModelType),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "vehicleModelType", void 0);
__decorate([
    IsArray(),
    IsEnum(VehicleModelType, { each: true }),
    IsOptional(),
    __metadata("design:type", Array)
], GetManyReservationsQueryDto.prototype, "vehicleModelTypes", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "branchId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "garageId", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "canceledAt", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "managerId", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "releaseManagerNameKeyword", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "customerNameKeyword", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "customerPhoneNumberKeyword", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDto.prototype, "customerAirportArrived", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "driverNameKeyword", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "driverPhoneNumberKeyword", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "reservedVehicleNumberKeyword", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "assignedVehicleNumberKeyword", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "vendorNameKeyword", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDto.prototype, "expectedDeposit", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "expectedDepositDate", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "expectedDepositDateFrom", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "expectedDepositDateTo", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDto.prototype, "includesInsuranceOption", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDto.prototype, "beforeLoan", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "dispatchAreaId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "pickUpGarageId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "dropOffGarageId", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDto.prototype, "releasePreparation", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDto.prototype, "hasAssignedVehicle", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDto.prototype, "hasDispatchArea", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDto.prototype, "isWanted", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyReservationsQueryDto.prototype, "wantedVendorName", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "extraItemId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "convenienceEquipmentId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyReservationsQueryDto.prototype, "insuranceId", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyReservationsQueryDto.prototype, "hasExtension", void 0);
