import { Box, Button, Dialog, Typography } from '@mui/material';
import { type ReservationDto } from '@orcar/common';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getReservations } from '@/apis/reservation.api';
import BottomButtons from '@/components/BottomButtons';
import LoadingDialog from '@/components/LoadingDialog';
import NumberPad from '@/components/NumberPad';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import TitleMessage from '@/components/TitleMessage';
import { useGetSetting } from '@/hooks/company-setting.hook';
import { useSettingsStore } from '@/store/useSettingsStore';
import ConfirmSureUserDialog from './components/ConfirmSureUserDialog';
import ManyResultDialog from './components/ManyResultDialog';
import NoResultDialog from './components/NoResultDialog';
import TooEarlyDialog from '../terms/components/TooEarlyDialog';
import VehicleUnreadyDialog from '../terms/components/VehicleUnreadyDialog';

const StartPage = () => {
  const [phoneNumber, setPhoneNumber] = useState('010');

  const [noResultDialogOpen, setNoResultDialogOpen] = useState(false);
  const [manyResultDialogOpen, setManyResultDialogOpen] = useState(false);
  const [confirmSureUserDialogState, setConfirmSureUserDialogState] = useState({
    open: false,
    reservationId: '',
  });
  const [vehicleUnready, setVehicleUnready] = useState(false);
  const [tooEarly, setTooEarly] = useState(false);
  const [unavailableReservation, setUnavailableReservation] =
    useState<ReservationDto>();
  const [isFetching, setIsFetching] = useState(false);
  const [reservations, setReservations] = useState<ReservationDto[]>([]);
  const [openIsOnlyForSureUserDialog, setOpenIsOnlyForSureUserDialog] =
    useState(false);

  const navigate = useNavigate();
  const isOnlyForSureUser = useSettingsStore(
    (state) => state.isOnlyForSureUser,
  );

  const { data: setting } = useGetSetting();

  const pickUpTimeLimit = setting?.kioskEarlyPickUpTimeLimit || 0;

  const handleIsOnlyForSureUserDialog = (value: boolean) => {
    setOpenIsOnlyForSureUserDialog(value);
    if (!value) {
      setManyResultDialogOpen(false);
      setPhoneNumber('010');
    }
  };

  const getIsRentalAvailable = (reservation: ReservationDto) => {
    setUnavailableReservation(reservation);

    if (dayjs(reservation.pickUpAt).diff(dayjs(), 'minute') > pickUpTimeLimit) {
      setTooEarly(true);
      return false;
    }

    if (
      reservation.dispatchAreaId === null ||
      reservation.assignedVehicleId === null ||
      reservation.assignedVehicle?.garageId === null
    ) {
      setVehicleUnready(true);
      return false;
    }

    return true;
  };

  const handleGetReservations = useCallback(async () => {
    setIsFetching(true);

    try {
      const { data: reservations } = await getReservations({
        customerPhoneNumberKeyword: phoneNumber || '',
        toPickUpAt: dayjs().endOf('day').toISOString(),
        fromDropOffAtTime: dayjs().toISOString(),
        beforeLoan: true,
      });

      setReservations(reservations);

      if (reservations.length === 0) {
        setNoResultDialogOpen(true);
      } else if (reservations.length === 1) {
        const alreadyCheckedInViaSure = !!reservations[0].contractor.sureUserId;

        if (alreadyCheckedInViaSure) {
          const isRentalAvailable = getIsRentalAvailable(reservations[0]);
          if (!isRentalAvailable) return;

          setConfirmSureUserDialogState({
            open: true,
            reservationId: reservations[0].id,
          });
        } else {
          if (isOnlyForSureUser) {
            handleIsOnlyForSureUserDialog(true);
            return;
          } else {
            navigate(`/${reservations[0].id}/terms`);
          }
        }
      } else {
        setManyResultDialogOpen(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }, [phoneNumber, navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        paddingBottom: '240px',
      }}
    >
      <Box
        sx={{
          paddingTop: '100px',
          paddingX: '80px',
          flexGrow: 1,
        }}
      >
        <TitleMessage message={['예약자의 전화번호를', '입력해주세요.']} />
        <Box sx={{ height: '56px' }}></Box>
        <PhoneNumberInput
          value={phoneNumber}
          onChange={(value) => {
            setPhoneNumber(value);
          }}
        />
      </Box>
      <NumberPad
        sx={{
          width: '100%',
          height: 960,
          borderTop: '1px solid #d4d4d4',
        }}
        customButtonLabel='010'
        onClick={(value) => {
          setPhoneNumber((current) => (current + value).slice(0, 11));
        }}
        onDelete={() => {
          setPhoneNumber((current) => current.slice(0, -1));
        }}
      />
      <BottomButtons
        label='입력 완료'
        disabled={phoneNumber.length < 9 || phoneNumber.length > 11}
        onClick={() => {
          handleGetReservations();
        }}
      />
      <NoResultDialog
        open={noResultDialogOpen}
        onConfirm={() => {
          setNoResultDialogOpen(false);
          setPhoneNumber('010');
        }}
      />
      <ManyResultDialog
        open={manyResultDialogOpen}
        reservations={reservations}
        isOnlyForSureUser={isOnlyForSureUser}
        openConfirmSureUserDialog={(reservationId: string) => {
          setManyResultDialogOpen(false);
          setConfirmSureUserDialogState({ open: true, reservationId });
        }}
        onCancel={() => {
          setManyResultDialogOpen(false);
          setPhoneNumber('010');
        }}
        getIsRentalAvailable={getIsRentalAvailable}
        handleIsOnlyForSureUserDialog={handleIsOnlyForSureUserDialog}
      />
      <ConfirmSureUserDialog
        open={confirmSureUserDialogState.open}
        onConfirm={() => {
          setConfirmSureUserDialogState((prev) => ({ ...prev, open: false }));
          navigate(`/${confirmSureUserDialogState.reservationId}/payment`);
        }}
      />
      <LoadingDialog open={isFetching} content='예약을 검색중입니다.' />
      {unavailableReservation && (
        <VehicleUnreadyDialog
          open={vehicleUnready}
          reservation={unavailableReservation}
          onConfirm={() => {
            setVehicleUnready(false);
            setTooEarly(false);
            setManyResultDialogOpen(false);
            setPhoneNumber('010');
          }}
        />
      )}
      {unavailableReservation && (
        <TooEarlyDialog
          open={tooEarly}
          reservation={unavailableReservation}
          onConfirm={() => {
            setVehicleUnready(false);
            setTooEarly(false);
            setManyResultDialogOpen(false);
            setPhoneNumber('010');
          }}
        />
      )}
      {openIsOnlyForSureUserDialog && (
        <Dialog open={openIsOnlyForSureUserDialog}>
          <Typography fontSize={40} fontWeight='bold' sx={{ marginBottom: 2 }}>
            데스크 문의
          </Typography>
          <Typography
            fontSize={32}
            fontWeight={400}
            color='text.secondary'
            lineHeight='44px'
            sx={{ marginBottom: 5 }}
          >
            사전 체크인(슈어) 전용 키오스크 입니다. 다른 키오스크를
            이용해주세요.
          </Typography>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            sx={{
              height: 100,
              fontSize: 40,
              fontWeight: 500,
            }}
            onClick={() => {
              handleIsOnlyForSureUserDialog(false);
            }}
          >
            확인
          </Button>
        </Dialog>
      )}
    </Box>
  );
};

export default StartPage;
