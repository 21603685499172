import { type LoginResponseDto, UserRole } from '@orcar/common';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = LoginResponseDto;

type Action = {
  setLoginInformation: (value: LoginResponseDto) => void;
  initializeLoginInformation: () => void;
};

const initialState = {
  login: false,
  id: 0,
  username: '',
  name: '',
  companyName: '',
  authority: null,
  role: UserRole.NORMAL,
  landingPage: '',
  vendor: null,
  isOnlineContract: false,
};

export const useAuthStore = create<State & Action>()(
  persist(
    (set) => ({
      ...initialState,
      setLoginInformation: (value) => set(() => value),
      initializeLoginInformation: () => set(() => ({ ...initialState })),
    }),
    { name: 'auth' },
  ),
);
