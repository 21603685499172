import { ExtraItemFeeCriterion } from '../enums';
import { getRentalDays } from './date.util';
const getUnitDays = (feeStandard) => {
    switch (feeStandard) {
        case ExtraItemFeeCriterion.ONE:
            return 1;
        case ExtraItemFeeCriterion.TWO:
            return 2;
        case ExtraItemFeeCriterion.THREE:
            return 3;
        case ExtraItemFeeCriterion.FOUR:
            return 4;
        case ExtraItemFeeCriterion.FIVE:
            return 5;
        case ExtraItemFeeCriterion.SIX:
            return 6;
        case ExtraItemFeeCriterion.SEVEN:
            return 7;
        default:
            throw new Error('Invalid extra option fee standard');
    }
};
export const calculateExtraItemFee = (extraItem, days) => {
    if (extraItem.feeCriterion === ExtraItemFeeCriterion.RESERVATION) {
        return extraItem.fee;
    }
    const unitDays = getUnitDays(extraItem.feeCriterion);
    return extraItem.fee * Math.ceil(days / unitDays);
};
export const calculateExtraItemFeeForReservation = (extraItem, reservation) => {
    const days = getRentalDays(reservation.pickUpAt, reservation.actualDropOffAt);
    return calculateExtraItemFee(extraItem, days);
};
