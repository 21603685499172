var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { IsArray, IsDateString, IsEnum, IsNumber, IsOptional, } from 'class-validator';
import { GetManyQueryDto } from '../../../classes/common/request/get-many-query.dto';
import { VehicleModelType } from '../../../enums';
import { PartialType } from '../../../utils';
export class GetManyDailyFeesQueryDto extends PartialType(GetManyQueryDto) {
}
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], GetManyDailyFeesQueryDto.prototype, "feeGradeId", void 0);
__decorate([
    Type(() => Number),
    IsArray(),
    IsNumber({}, { each: true }),
    IsOptional(),
    __metadata("design:type", Array)
], GetManyDailyFeesQueryDto.prototype, "vehicleModelIds", void 0);
__decorate([
    IsEnum(VehicleModelType),
    IsOptional(),
    __metadata("design:type", String)
], GetManyDailyFeesQueryDto.prototype, "vehicleModelType", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyDailyFeesQueryDto.prototype, "startDate", void 0);
__decorate([
    IsDateString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyDailyFeesQueryDto.prototype, "endDate", void 0);
