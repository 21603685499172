import { Box, Button, Dialog, Switch, Typography } from '@mui/material';
import { ManagerApprovalType } from '@orcar/common';
import dayjs from 'dayjs';
import logger from 'electron-log';
import { useEffect, useState } from 'react';
import { useLogout } from '@/hooks/auth.hook';
import { useGetProfile } from '@/hooks/company-profile.hook';
import { usePrintReceipt } from '@/hooks/print-receipt.hook';
import { useAuthStore } from '@/store/useAuthStore';
import { useSettingsStore } from '@/store/useSettingsStore';
import { type KioskSettings } from 'main/services/settings.service';
import LoadingDialog from './LoadingDialog';
import { GreyButton } from './StyledMuiComponent';
import TextInput from './TextInput';

interface AdminDialogProps {
  open: boolean;
  onClose: () => void;
}

const AdminDialog = ({ open, onClose }: AdminDialogProps) => {
  const [version, setVersion] = useState('');
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUpdateReady, setIsUpdateReady] = useState(false);
  const [settingsPage, setSettingsPage] = useState(0);
  const [settings, setSettings] = useState<KioskSettings>({
    tid: '',
    printerPort: '',
    rendererUrl: '',
    isOnlyForSureUser: false,
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const setGlobalSettings = useSettingsStore((state) => state.setSettings);
  const username = useAuthStore((state) => state.username);

  const { printReceipt } = usePrintReceipt();

  const { mutateAsync: logout } = useLogout();
  const { data: companyProfile } = useGetProfile();

  useEffect(() => {
    window.electron?.getVersion().then((version) => {
      setVersion(version);
    });

    window.electron?.onDownloadProgress((_event, progressInfo) => {
      setProgress(progressInfo.percent);
    });

    window.electron?.onUpdateDownloaded(() => {
      setIsUpdateReady(true);
    });

    window.electron?.onUpdateAvailable((_event, isAvailable) => {
      setIsUpdateAvailable(isAvailable);
    });
  }, []);

  const resetSettings = async () => {
    const savedSettings = await window.settings.getAll();
    setGlobalSettings(savedSettings);
    setSettings(savedSettings);
  };

  const saveSettings = async () => {
    await window.settings.setAll(settings);
    setGlobalSettings(settings);
    setSettingsPage(0);
  };

  useEffect(() => {
    if (open) {
      window.electron?.checkForUpdates();
      setSettingsPage(0);
    }
  }, [open]);

  useEffect(() => {
    if (settingsPage) {
      resetSettings();
    }
  }, [settingsPage]);

  return (
    <>
      <Dialog open={open}>
        {settingsPage === 1 ? (
          <Box sx={{ width: '100%' }}>
            <Typography
              fontSize={40}
              fontWeight='bold'
              onClick={() => {
                setSettingsPage(2);
              }}
            >
              기기 설정
            </Typography>
            <Box sx={{ height: '40px' }} />
            <Typography
              fontSize='28px'
              sx={{ marginBottom: '20px', textAlign: 'left' }}
            >
              결제 단말기 TID
            </Typography>
            <TextInput
              value={settings.tid}
              onChange={(value) => {
                setSettings((current) => ({ ...current, tid: value }));
              }}
              textFieldSx={{
                width: '100%',
                height: '100px',
              }}
              inputSx={{
                fontSize: '36px',
              }}
            />
            <Box sx={{ height: '40px' }} />
            <Typography
              fontSize='28px'
              sx={{ marginBottom: '20px', textAlign: 'left' }}
            >
              영수증 프린터 포트
            </Typography>
            <Box display='flex' width='100%' height='100px'>
              <TextInput
                value={settings.printerPort}
                onChange={(value) => {
                  setSettings((current) => ({
                    ...current,
                    printerPort: value,
                  }));
                }}
                sx={{
                  flexGrow: 1,
                }}
                textFieldSx={{
                  borderRight: 'none',
                  width: '100%',
                  height: '100%',
                }}
                inputSx={{
                  fontSize: '36px',
                }}
              />
              <Button
                variant='contained'
                sx={{
                  paddingX: '36px',
                  height: '100%',
                  fontSize: '36px',
                }}
                onClick={async () => {
                  try {
                    await printReceipt(
                      {
                        dispatchArea: '42',
                        vehicleNumber: '123허9876',
                        contractorName: '홍길동',
                        driverName: 'John Doe',
                        pickUpAt: dayjs().toDate(),
                        actualDropOffAt: dayjs().add(1, 'day').toDate(),
                        vehicleModelName: '테슬라 모델 S',
                      },
                      {
                        paidAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                        paymentMethod: '신용카드',
                        cardNumber: '12345678********',
                        installment: '3개월',
                        supply: `${(38182).toLocaleString()}원`,
                        vat: `${(3818).toLocaleString()}원`,
                        amount: `${(42000).toLocaleString()}원`,
                      },
                      [
                        ManagerApprovalType.SKIP_DRIVER_LICENSE_IMAGE,
                        ManagerApprovalType.EDIT_DRIVER_LICENSE_INFO,
                        ManagerApprovalType.SKIP_DRIVER_LICENSE_IMAGE,
                        ManagerApprovalType.SKIP_PAYMENT,
                      ],
                    );
                  } catch (error) {
                    logger.error(error instanceof Error ? error.stack : error);
                  }
                }}
              >
                <Typography fontSize='36px' fontWeight='medium'>
                  테스트
                </Typography>
              </Button>
            </Box>
            <Box sx={{ height: '40px' }} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography fontSize='28px'>사전 체크인(슈어) 전용</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography
                  sx={{
                    fontSize: 20,
                  }}
                >
                  미사용
                </Typography>
                <Switch
                  checked={settings.isOnlyForSureUser}
                  onChange={(e) => {
                    setSettings((current) => ({
                      ...current,
                      isOnlyForSureUser: e.target.checked,
                    }));
                  }}
                  sx={{
                    width: 120,
                    height: 68,
                    p: '0px',
                    borderRadius: 60,
                    display: 'flex',
                    '& .MuiSwitch-thumb': {
                      width: 56,
                      height: 56,
                      p: '0px',
                    },
                    '& .MuiSwitch-switchBase': {
                      p: '6px',
                      '&.Mui-checked': {
                        transform: 'translateX(52px)',
                      },
                    },
                  }}
                />
                <Typography
                  sx={{
                    fontSize: 20,
                  }}
                >
                  사용
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                mt: 8,
                display: 'grid',
                gridTemplateColumns: '1fr 20px 1fr',
                width: '100%',
              }}
            >
              <GreyButton
                sx={{
                  width: '100%',
                  fontSize: 40,
                }}
                onClick={() => {
                  setSettingsPage(0);
                }}
              >
                <Typography fontSize={40} fontWeight='medium'>
                  취소
                </Typography>
              </GreyButton>
              <Box height={'20px'}></Box>
              <Button
                variant='contained'
                sx={{
                  height: '100%',
                  fontSize: 40,
                }}
                onClick={() => {
                  saveSettings();
                }}
              >
                <Typography fontSize={40} fontWeight='medium'>
                  저장
                </Typography>
              </Button>
            </Box>
          </Box>
        ) : settingsPage === 2 ? (
          <Box sx={{ width: '100%' }}>
            <Typography
              fontSize={40}
              fontWeight='bold'
              onClick={() => {
                setSettingsPage(1);
              }}
            >
              기기 설정 (2)
            </Typography>
            <Box sx={{ height: '40px' }} />
            <Typography
              fontSize='28px'
              sx={{ marginBottom: '20px', textAlign: 'left' }}
            >
              Renderer URL
            </Typography>
            <TextInput
              value={settings.rendererUrl}
              onChange={(value) => {
                setSettings((current) => ({ ...current, rendererUrl: value }));
              }}
              textFieldSx={{
                width: '100%',
                height: '100px',
              }}
              inputSx={{
                fontSize: '36px',
              }}
            />
            <Box
              sx={{
                mt: 8,
                display: 'grid',
                gridTemplateColumns: '1fr 20px 1fr',
                width: '100%',
              }}
            >
              <GreyButton
                sx={{
                  width: '100%',
                  fontSize: 40,
                }}
                onClick={() => {
                  setSettingsPage(0);
                }}
              >
                <Typography fontSize={40} fontWeight='medium'>
                  취소
                </Typography>
              </GreyButton>
              <Box height={'20px'}></Box>
              <Button
                variant='contained'
                sx={{
                  height: '100%',
                  fontSize: 40,
                }}
                onClick={() => {
                  saveSettings();
                }}
              >
                <Typography fontSize={40} fontWeight='medium'>
                  저장
                </Typography>
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Typography fontSize={40} fontWeight='bold'>
              오르카 KIOSK
            </Typography>
            <Box sx={{ height: '16px' }} />
            <Typography
              fontSize={32}
              fontWeight='regular'
              color='text.secondary'
            >
              v{version}
            </Typography>
            <Box sx={{ height: '16px' }} />
            <Typography
              fontSize={32}
              fontWeight='regular'
              color='text.secondary'
            >
              {companyProfile?.name ?? '-'} | {username}
            </Typography>
            {isUpdateAvailable && (
              <Button
                variant='contained'
                fullWidth
                sx={{ mt: 4, height: 100, fontSize: 40, fontWeight: 500 }}
                disabled={!isUpdateReady}
                color='success'
                onClick={() => {
                  setIsUpdating(true);
                  window.electron.quitAndInstall();
                }}
              >
                {isUpdateReady
                  ? '프로그램 종료 및 업데이트'
                  : isUpdateAvailable
                  ? `업데이트 다운로드 중... (${Math.round(progress)}%)`
                  : ''}
              </Button>
            )}
            <Button
              variant='contained'
              fullWidth
              sx={{ mt: 4, height: 100, fontSize: 40, fontWeight: 500 }}
              color='warning'
              onClick={() => {
                onClose();
                logout();
              }}
            >
              로그아웃
            </Button>
            <Button
              variant='contained'
              fullWidth
              sx={{ mt: 4, height: 100, fontSize: 40, fontWeight: 500 }}
              onClick={() => {
                setSettingsPage(1);
              }}
            >
              기기 설정
            </Button>
            <Button
              variant='contained'
              fullWidth
              color='inherit'
              sx={{ mt: 4, height: 100, fontSize: 40, fontWeight: 500 }}
              onClick={onClose}
            >
              닫기
            </Button>
          </Box>
        )}
      </Dialog>
      <LoadingDialog open={isUpdating} content='프로그램이 곧 종료됩니다.' />
    </>
  );
};

export default AdminDialog;
