import { type AxiosException, type LoginResponseDto } from '@orcar/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { login, logout, refreshLogin } from '@/apis/auth.api';
import { type CompanyLoginForm } from '@/interfaces/company-login-form';
import { useAuthStore } from '@/store/useAuthStore';

export const useLogin = () => {
  const queryClient = useQueryClient();

  return useMutation<
    LoginResponseDto,
    AxiosError<AxiosException>,
    CompanyLoginForm
  >({
    mutationFn: login,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
  });
};

export const useRefreshLogin = () => {
  const { mutate: logout } = useLogout();
  const setLoginInformation = useAuthStore(
    (state) => state.setLoginInformation,
  );

  return useMutation<LoginResponseDto, AxiosError<AxiosException>>({
    mutationFn: refreshLogin,
    onSuccess: (data) => {
      setLoginInformation(data);
    },
    onError: (error) => {
      if (error.response?.status === 401) {
        logout();
      }
    },
  });
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const initializeLoginInformation = useAuthStore(
    (state) => state.initializeLoginInformation,
  );

  return useMutation<void, AxiosError<AxiosException>>({
    mutationFn: logout,
    onSettled: () => {
      initializeLoginInformation();
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
  });
};
