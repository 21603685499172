import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { socket } from '@/apis/websocketClient';
import InactivityDialog from '@/components/InactivityDialog';
import TopProgressBar from '@/components/TopProgressBar';
import { useGetReservation } from '@/hooks/reservation.hook';
import { useAuthStore } from '@/store/useAuthStore';

const CommonLayout = () => {
  const login = useAuthStore((state) => state.login);

  const id = useParams().id;

  const { data } = useGetReservation({
    id,
  });

  const isExistData =
    !!data && !!data.assignedVehicleId && !!data.dispatchAreaId;

  useEffect(() => {
    if (login && !socket.connected) {
      socket.connect();
    } else {
      socket.disconnect();
    }
  }, [login]);

  useEffect(() => {
    const handleConnectError = (error: any) => {
      if (
        error &&
        error.message &&
        (error.message.includes('Unauthorized') ||
          error.message.includes('401'))
      ) {
        socket.disconnect();
        socket.connect();
      }
    };

    socket.on('connect_error', handleConnectError);

    return () => {
      socket.off('connect_error', handleConnectError);
    };
  }, []);

  const emitCheckIn = useCallback(() => {
    if (isExistData) {
      socket.emit('check-in', { id });
    }
  }, [isExistData, id]);

  useEffect(() => {
    if (socket.connected) {
      emitCheckIn();
    }
    return () => {
      socket.emit('cancel-check-in');
    };
  }, [emitCheckIn]);

  useEffect(() => {
    socket.on('connect', emitCheckIn);
    return () => {
      socket.off('connect', emitCheckIn);
    };
  }, [emitCheckIn]);

  return (
    <>
      {!login && <Navigate to='/login' />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100wv',
          height: '100vh',
          backgroundColor: '#f5f5f5',
        }}
      >
        <TopProgressBar />
        <Outlet />
      </Box>
      <InactivityDialog />
    </>
  );
};

export default CommonLayout;
