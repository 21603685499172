var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { ChecklistItemDto } from '../../../classes/checklist-item';
export class CompanySettingDto {
    constructor(initializer) {
        this.feeCategory = initializer.feeCategory;
        this.feeCalculationBasis = initializer.feeCalculationBasis;
        this.fuelRateUnit = initializer.fuelRateUnit;
        this.defaultPickUpGarageId = initializer.defaultPickUpGarageId;
        this.defaultDropOffGarageId = initializer.defaultDropOffGarageId;
        this.limitNumberOfReservation = initializer.limitNumberOfReservation;
        this.licenseCertification = initializer.licenseCertification;
        this.defaultVendorId = initializer.defaultVendorId;
        this.landingPage = initializer.landingPage;
        this.checklistItems = initializer.checklistItems;
        this.setUpTime = initializer.setUpTime;
        this.swapVehicle = initializer.swapVehicle;
        this.swapBlock = initializer.swapBlock;
        this.kioskEarlyPickUpTimeLimit = initializer.kioskEarlyPickUpTimeLimit;
    }
}
__decorate([
    Type(() => ChecklistItemDto),
    __metadata("design:type", Array)
], CompanySettingDto.prototype, "checklistItems", void 0);
