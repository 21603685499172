import { Box, Divider, type SxProps } from '@mui/material';
import { formatPhoneNumber, type ReservationDto } from '@orcar/common';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import {
  ChipButton,
  TableContent,
  TableLabel,
} from '@/components/StyledMuiComponent';
import VehicleInsuranceSelectDialog from './VehicleInsuranceSelectDialog';

type ReservationSummaryCardProps = {
  sx?: SxProps;
  reservation: ReservationDto;
};

const getInsuranceName = (reservation: ReservationDto): string => {
  if (reservation.insuranceOption === 1) {
    return reservation.vehicleModel.insurance1?.name || '일반자차';
  } else if (reservation.insuranceOption === 2) {
    return reservation.vehicleModel.insurance2?.name || '고급자차';
  } else if (reservation.insurances.length > 0) {
    return reservation.insurances[0].insurance.name;
  }
  return '미가입';
};

const getInsuranceContent = (reservation: ReservationDto): string => {
  const contentArray: string[] = [];

  if (reservation.insuranceOption === 1 || reservation.insuranceOption === 2) {
    const insurance =
      reservation.vehicleModel[`insurance${reservation.insuranceOption}`];

    if (!insurance) {
      return '';
    }

    if (insurance.limit) contentArray.push(`보장한도: ${insurance.limit}`);
    if (insurance.indemnification)
      contentArray.push(`면책금: ${insurance.indemnification}`);
    if (insurance.compensation)
      contentArray.push(`휴차보상료: ${insurance.compensation}`);

    return contentArray.join(' / ');
  } else if (reservation.insurances.length > 0) {
    const vendorGrade = reservation.vendor.insuranceGrade;
    const insuranceDetail =
      reservation.insurances[0].insurance[`detail${vendorGrade}`];

    if (insuranceDetail.limit)
      contentArray.push(`보장한도: ${insuranceDetail.limit}`);
    if (insuranceDetail.indemnification)
      contentArray.push(`면책금: ${insuranceDetail.indemnification}`);
    if (insuranceDetail.compensation)
      contentArray.push(`휴차보상료: ${insuranceDetail.compensation}`);

    return contentArray.join(' / ');
  }
  return '차량손해면책 미가입 시 차량 사고가 발생하면 차량 수리비, 휴차보상료 등의 비용이 청구될 수 있습니다.';
};

const getInsuranceFee = (reservation: ReservationDto): string => {
  if (!!reservation.insuranceOption) {
    return '차량요금에 포함';
  } else if (reservation.insurances.length > 0) {
    return reservation.insurances[0].directPaymentPrice
      ? `${reservation.insurances[0].directPaymentPrice.toLocaleString()}원`
      : '0원';
  }
  return '미가입';
};

const ReservationDetailCard = ({
  sx,
  reservation,
}: ReservationSummaryCardProps) => {
  const [
    vehicleInsuranceSelectDialogOpen,
    setVehicleInsuranceSelectDialogOpen,
  ] = useState(false);

  return (
    <Box
      sx={{
        ...sx,
        position: 'relative',
        background: '#ffffff',
        border: '1px solid #d4d4d4',
        padding: '40px',
        maxHeight: '760px',
        overflowY: 'scroll',

        '&::-webkit-scrollbar': {
          width: '24px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#c7c7c7',
          borderRadius: '30px',
          '&:hover': {
            backgroundColor: '#878787',
          },
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#fafafa',
          borderLeft: '1px solid #d4d4d4',
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 145px 1fr)',
          rowGap: '12px',
        }}
      >
        <TableLabel>예약번호</TableLabel>
        <TableContent>{reservation.id}</TableContent>
        <TableLabel>예약일시</TableLabel>
        <TableContent>
          {dayjs(reservation.createdAt).format('YY.MM.DD HH:mm')}
        </TableContent>
        <TableLabel>예약자</TableLabel>
        <TableContent>{reservation.customerName1}</TableContent>
        <TableLabel>전화번호</TableLabel>
        <TableContent>
          {reservation.customerPhoneNumber1 &&
            formatPhoneNumber(reservation.customerPhoneNumber1)}
        </TableContent>
        <Divider sx={{ gridColumn: '1 / -1', marginY: '16px' }} />
        <TableLabel>출발일시</TableLabel>
        <TableContent sx={{ color: 'primary.main' }}>
          {dayjs(reservation.pickUpAt).format('YY.MM.DD HH:mm')}
        </TableContent>
        <TableLabel>반납일시</TableLabel>
        <TableContent sx={{ color: 'primary.main' }}>
          {dayjs(reservation.actualDropOffAt).format('YY.MM.DD HH:mm')}
        </TableContent>
        <TableLabel>반납장소</TableLabel>
        <TableContent>{reservation.dropOffGarage?.name || '미정'}</TableContent>
        <TableLabel>차량요금</TableLabel>
        <TableContent>
          {`${(reservation.directPaymentPrice ?? 0).toLocaleString()}원`}
        </TableContent>
        <Divider sx={{ gridColumn: '1 / -1', marginY: '16px' }} />
        <Box
          sx={{ gridColumn: '1 / -1', display: 'flex', alignItems: 'center' }}
        >
          <TableLabel sx={{ marginRight: '32px' }}>차종</TableLabel>
          <TableContent>{reservation.vehicleModel.name}</TableContent>
        </Box>
        <Divider sx={{ gridColumn: '1 / -1', marginY: '16px' }} />
        <Box
          sx={{ gridColumn: '1 / -1', display: 'flex', alignItems: 'center' }}
        >
          <TableLabel sx={{ marginRight: '32px' }}>차량손해면책</TableLabel>
          <TableContent>{getInsuranceName(reservation)}</TableContent>
          <Box sx={{ marginLeft: 'auto' }} />
          {!reservation.insuranceOption && (
            <ChipButton
              sx={{ marginY: -1 }}
              onClick={() => {
                setVehicleInsuranceSelectDialogOpen(true);
              }}
            >
              {reservation.insurances.length > 0 ? '변경' : '추가'}
            </ChipButton>
          )}
        </Box>
        <Divider sx={{ gridColumn: '1 / -1', marginY: '16px' }} />
        <TableLabel>자차내용</TableLabel>
        <TableContent
          sx={{ gridColumn: '2 / -1', lineHeight: 1.2, whiteSpace: 'pre-line' }}
        >
          {getInsuranceContent(reservation)}
        </TableContent>
        <TableLabel>자차요금</TableLabel>
        <TableContent sx={{ gridColumn: '2 / -1' }}>
          {getInsuranceFee(reservation)}
        </TableContent>
        {(reservation.extraItems.length > 0 ||
          reservation.conveniences.length > 0) && (
          <>
            <Divider sx={{ gridColumn: '1 / -1', marginY: '16px' }} />
            <Box
              sx={{
                gridColumn: '1 / -1',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TableLabel>기타상품</TableLabel>
            </Box>
            {reservation.extraItems.map((item) => (
              <React.Fragment key={item.id}>
                <Box
                  sx={{
                    gridColumn: '1 / -1',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TableLabel sx={{ color: '#333', fontWeight: 'bold' }}>
                    {item.extraItem.name}
                  </TableLabel>
                  <Box sx={{ flexGrow: 1 }} />
                  <TableLabel sx={{ color: '#333', fontWeight: 'bold' }}>
                    {item.directPaymentPrice ?? 0}원
                  </TableLabel>
                </Box>
                <Box
                  key={item.id}
                  sx={{
                    gridColumn: '1 / -1',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TableLabel>{item.extraItem.detail}</TableLabel>
                </Box>
              </React.Fragment>
            ))}
            {reservation.conveniences.map((item) => (
              <Box
                key={item.id}
                sx={{
                  gridColumn: '1 / -1',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TableLabel sx={{ color: '#333', fontWeight: 'bold' }}>
                  {item.convenienceEquipment.name}
                </TableLabel>
                <Box sx={{ flexGrow: 1 }} />
                <TableLabel sx={{ color: '#333', fontWeight: 'bold' }}>
                  {item.directPaymentPrice}원
                </TableLabel>
              </Box>
            ))}
          </>
        )}
      </Box>
      <VehicleInsuranceSelectDialog
        open={vehicleInsuranceSelectDialogOpen}
        onClose={() => {
          setVehicleInsuranceSelectDialogOpen(false);
        }}
        reservation={reservation}
      />
    </Box>
  );
};

export default ReservationDetailCard;
