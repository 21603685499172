import { Box, Divider, Typography } from '@mui/material';
import { type ReservationDto } from '@orcar/common';
import dayjs from 'dayjs';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '@/components/ConfirmDialog';
import {
  ChipButton,
  TableContent,
  TableLabel,
} from '@/components/StyledMuiComponent';

type ReservationPersonCardProps = {
  reservation: ReservationDto;
  payFee: number;
};

const ContractSummaryCard = ({
  reservation,
  payFee,
}: ReservationPersonCardProps) => {
  const navigate = useNavigate();
  const [openAskSecondDriver, setOpenAskSecondDriver] = useState(false);

  const hasInsurance =
    reservation.insuranceOption !== 0 || reservation.insurances.length > 0;

  const insurance =
    reservation.insuranceOption === 1 || reservation.insuranceOption === 2
      ? reservation.vehicleModel[`insurance${reservation.insuranceOption}`]
      : reservation.insurances.length > 0
      ? reservation.insurances[0].insurance
      : null;

  const insuranceIndemnification =
    (reservation.insuranceOption === 1 || reservation.insuranceOption === 2
      ? reservation.vehicleModel[`insurance${reservation.insuranceOption}`]
          ?.indemnification
      : reservation.insurances.length > 0
      ? reservation.insurances[0]?.insurance[
          `detail${reservation.vendor.insuranceGrade}`
        ].indemnification
      : '수리비 전액 부담') ?? '';

  const insuranceCompensation =
    (reservation.insuranceOption === 1 || reservation.insuranceOption === 2
      ? reservation.vehicleModel[`insurance${reservation.insuranceOption}`]
          ?.compensation
      : reservation.insurances.length > 0
      ? reservation.insurances[0].insurance[
          `detail${reservation.vendor.insuranceGrade}`
        ].compensation
      : '휴차보상료 전액 부담') ?? '';

  return (
    <Box
      sx={{
        position: 'relative',
        border: '1px solid #d4d4d4',
        padding: '40px',
        paddingTop: 0,
        bgcolor: '#ffffff',
        maxHeight: payFee ? '504px' : '900px',
        overflowY: 'scroll',

        '&::-webkit-scrollbar': {
          width: '24px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#c7c7c7',
          borderRadius: '30px',
          '&:hover': {
            backgroundColor: '#878787',
          },
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#fafafa',
          borderLeft: '1px solid #d4d4d4',
        },
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          bgcolor: '#ffffff',
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: 'repeat(2, 174px 1fr)',
          paddingTop: '40px',
        }}
      >
        <TableLabel marginRight={'32px'}>제1 운전자</TableLabel>
        <TableContent>{reservation.contractor.name}</TableContent>
        <TableLabel marginRight={'32px'}>제2 운전자</TableLabel>
        <TableContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {reservation.driver.name ? (
              reservation.driver.name
            ) : (
              <Fragment>
                없음
                <ChipButton
                  sx={{
                    marginY: -1,
                  }}
                  buttonSize='large'
                  onClick={() => {
                    setOpenAskSecondDriver(true);
                  }}
                >
                  추가
                </ChipButton>
              </Fragment>
            )}
          </Box>
        </TableContent>
      </Box>
      <Divider sx={{ marginTop: '40px' }} />
      <Box
        sx={{
          display: 'flex',
          marginTop: '40px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Typography
            fontWeight={500}
            fontSize={32}
            sx={{
              marginBottom: '12px',
            }}
          >
            {reservation.vehicleModel.name}
          </Typography>
          <Typography
            fontWeight={'regular'}
            fontSize={32}
            color={'text.secondary'}
            sx={{
              marginBottom: '8px',
            }}
          >
            {dayjs(reservation.pickUpAt).format('YY.MM.DD HH:mm')}
            {' ~ '}
            {dayjs(reservation.actualDropOffAt).format('YY.MM.DD HH:mm')}
          </Typography>
          <Typography
            fontWeight={'regular'}
            fontSize={32}
            color={'text.secondary'}
          >
            {reservation.dropOffGarage?.name}
            {' 반납'}
          </Typography>
        </Box>
        <Typography fontWeight={'bold'} fontSize={32}>
          {!!reservation.directPaymentPrice
            ? `${reservation.directPaymentPrice.toLocaleString()}원`
            : null}
        </Typography>
      </Box>
      <Divider sx={{ marginY: '40px' }} />
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Typography
            fontWeight={500}
            fontSize={32}
            sx={{
              marginBottom: '12px',
            }}
          >
            {hasInsurance ? insurance?.name : '차량손해면책 미가입'}
          </Typography>
          <Typography
            fontWeight={'regular'}
            fontSize={32}
            color={'text.secondary'}
            sx={{
              marginBottom: '8px',
            }}
          >
            면책금: {insuranceIndemnification}
          </Typography>
          <Typography
            fontWeight={'regular'}
            fontSize={32}
            color={'text.secondary'}
          >
            휴차보상료 : {insuranceCompensation}
          </Typography>
        </Box>
        {!reservation.insuranceOption && (
          <Typography fontWeight={'bold'} fontSize={32}>
            {reservation.insurances &&
            reservation.insurances.length > 0 &&
            !!reservation.insurances[0].directPaymentPrice
              ? `${reservation.insurances[0].directPaymentPrice.toLocaleString()}원`
              : null}
          </Typography>
        )}
      </Box>
      {(reservation.extraItems.length > 0 ||
        reservation.conveniences.length > 0) && (
        <>
          <Divider sx={{ marginY: '40px' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <Typography
              fontWeight={500}
              fontSize={32}
              sx={{
                marginBottom: '12px',
              }}
            >
              기타상품
            </Typography>
            {reservation.extraItems.map((item) => (
              <Box key={item.id} sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                  }}
                >
                  <Typography
                    fontWeight={500}
                    fontSize={32}
                    sx={{
                      marginBottom: '12px',
                    }}
                  >
                    {item.extraItem.name}
                  </Typography>
                  <Typography
                    fontWeight={'regular'}
                    fontSize={32}
                    color={'text.secondary'}
                    sx={{
                      marginBottom: '8px',
                    }}
                  >
                    {item.extraItem.detail}
                  </Typography>
                </Box>
                <Typography
                  fontWeight={'bold'}
                  fontSize={32}
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {!!item.directPaymentPrice
                    ? `${item.directPaymentPrice.toLocaleString()}원`
                    : null}
                </Typography>
              </Box>
            ))}
            {reservation.conveniences.map((item) => (
              <Box key={item.id} sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    fontWeight={500}
                    fontSize={32}
                    sx={{
                      marginBottom: '12px',
                    }}
                  >
                    {item.convenienceEquipment.name}
                  </Typography>
                </Box>
                <Typography
                  fontWeight={'bold'}
                  fontSize={32}
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {!!item.directPaymentPrice
                    ? `${item.directPaymentPrice.toLocaleString()}원`
                    : null}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
      <ConfirmDialog
        open={openAskSecondDriver}
        title={'제2 운전자를 등록하시겠습니까?'}
        confirmButtonLabel='예'
        closeButtonLabel='아니오'
        onConfirm={() => {
          setOpenAskSecondDriver(false);
          navigate(`/${reservation.id}/driver-info/2`, { replace: true });
        }}
        onClose={() => {
          setOpenAskSecondDriver(false);
        }}
        onClickOutside={() => {
          setOpenAskSecondDriver(false);
        }}
      />
    </Box>
  );
};

export default ContractSummaryCard;
