export class ReservationStatisticBranchInfoDto {
    constructor({ id, vehicleCount, totalFee = 0, vehicleFee = 0, insuranceFee = 0, extraItemFee = 0, }) {
        this.id = id;
        this.vehicleCount = vehicleCount;
        this.totalFee = totalFee;
        this.vehicleFee = vehicleFee;
        this.insuranceFee = insuranceFee;
        this.extraItemFee = extraItemFee;
    }
}
