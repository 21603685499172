import { Box, Button, Dialog, Divider, Typography } from '@mui/material';
import {
  type InsuranceWithoutRelationsDto,
  type ReservationDto,
} from '@orcar/common';
import dayjs from 'dayjs';
import { useState } from 'react';
import { ChipButton, GreyButton } from '@/components/StyledMuiComponent';
import {
  useGetReservationInsuranceFee,
  useUpdateReservation,
} from '@/hooks/reservation.hook';
import { useGetVehicleModel } from '@/hooks/vehicle-model.hook';

type VehicleInsuranceSelectCardProps = {
  reservation: ReservationDto;
  open: boolean;
  onClose: () => void;
};

const VehicleInsuranceCard = ({
  selected,
  insurance,
  reservation,
  onClick,
}: {
  selected: boolean;
  insurance: InsuranceWithoutRelationsDto;
  reservation: ReservationDto;
  onClick?: () => void;
}) => {
  const insuranceGrade = reservation.vendor.insuranceGrade;

  const detail = insurance[`detail${insuranceGrade}`];

  const { data: insuranceFeeData } = useGetReservationInsuranceFee({
    params: {
      pickUpAt: dayjs(reservation.pickUpAt).format('YYYY-MM-DD HH:mm'),
      dropOffAt: dayjs(reservation.actualDropOffAt).format('YYYY-MM-DD HH:mm'),
      vendorId: reservation.vendorId,
      insuranceId: insurance.id,
    },
  });

  const constraints = [];
  if (detail.age) {
    constraints.push(`만 ${detail.age}세 이상`);
  }
  if (detail.experience) {
    constraints.push(`운전 경력 ${detail.experience}년 이상`);
  }

  return (
    <Box
      sx={(theme) => ({
        padding: '32px',
        border: '1px solid',
        borderColor: selected ? 'primary.main' : theme.palette.grey[200],
        background: selected ? 'rgba(51, 202, 119, 0.08)' : '#ffffff',
        marginBottom: 3.5,
      })}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography
          fontWeight='bold'
          fontSize={32}
          textAlign='left'
          color={selected ? 'primary.main' : 'text.primary'}
        >
          {insurance.name}
        </Typography>
        <ChipButton
          useIcon={false}
          sx={{
            marginTop: -1,
            fontWeight: 'normal',
            ...(selected && {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              borderColor: 'primary.main',
              ':hover': {
                borderColor: 'primary.main',
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
              },
            }),
          }}
        >
          {selected ? '선택됨' : '선택하기'}
        </ChipButton>
      </Box>
      <Divider
        sx={{
          marginY: '32px',
          borderColor: selected ? 'primary.main' : 'grey.200',
        }}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '200px 1fr',
          alignItems: 'center',
          rowGap: '20px',
        }}
      >
        <Typography
          textAlign='left'
          fontSize={28}
          color={selected ? 'rgba(51, 202, 119, 0.80)' : 'text.secondary'}
        >
          보장한도
        </Typography>
        <Typography
          textAlign='right'
          fontSize={28}
          color={selected ? 'primary.main' : 'text.primary'}
        >
          {detail.limit}
        </Typography>
        <Typography
          textAlign='left'
          fontSize={28}
          color={selected ? 'rgba(51, 202, 119, 0.80)' : 'text.secondary'}
        >
          면책금
        </Typography>
        <Typography
          textAlign='right'
          fontSize={28}
          color={selected ? 'primary.main' : 'text.primary'}
        >
          {detail.indemnification}
        </Typography>
        <Typography
          textAlign='left'
          fontSize={28}
          color={selected ? 'rgba(51, 202, 119, 0.80)' : 'text.secondary'}
        >
          휴차보상료
        </Typography>
        <Typography
          textAlign='right'
          fontSize={28}
          color={selected ? 'primary.main' : 'text.primary'}
        >
          {detail.compensation}
        </Typography>
        <Typography
          textAlign='left'
          fontSize={28}
          color={selected ? 'rgba(51, 202, 119, 0.80)' : 'text.secondary'}
        >
          가입제한
        </Typography>
        <Typography
          textAlign='right'
          fontSize={28}
          color={selected ? 'primary.main' : 'text.primary'}
        >
          {constraints.length > 0 ? constraints.join(', ') : '없음'}
        </Typography>
        <Typography
          textAlign='left'
          fontSize={28}
          color={selected ? 'rgba(51, 202, 119, 0.80)' : 'text.secondary'}
        >
          추가내용
        </Typography>
        <Typography
          textAlign='right'
          fontSize={28}
          color={selected ? 'primary.main' : 'text.primary'}
        >
          {detail.content || '없음'}
        </Typography>
        <Typography
          textAlign='left'
          fontSize={28}
          color={selected ? 'rgba(51, 202, 119, 0.80)' : 'text.secondary'}
        >
          자차요금
        </Typography>
        <Typography
          textAlign='right'
          fontSize={28}
          color={selected ? 'primary.main' : 'text.primary'}
        >
          {`${insuranceFeeData?.price.toLocaleString()}원`}
        </Typography>
      </Box>
    </Box>
  );
};

const VehicleInsuranceSelectDialog = (
  props: VehicleInsuranceSelectCardProps,
) => {
  const { reservation, open, onClose } = props;
  const [selectedInsuranceId, setSelectedInsuranceId] = useState<number>(
    reservation.insurances[0]?.insuranceId ?? 0,
  );

  const { data: vehicleModelData } = useGetVehicleModel(
    reservation.vehicleModelId,
  );

  const { data: insuranceFeeData, isPlaceholderData } =
    useGetReservationInsuranceFee({
      params: {
        pickUpAt: dayjs(reservation.pickUpAt).format('YYYY-MM-DD HH:mm'),
        dropOffAt: dayjs(reservation.actualDropOffAt).format(
          'YYYY-MM-DD HH:mm',
        ),
        vendorId: reservation.vendorId,
        insuranceId: selectedInsuranceId,
      },
    });

  const updateReservation = useUpdateReservation();

  const handleUpdateInsuranceId = () => {
    if (isPlaceholderData) {
      return;
    }

    if (!insuranceFeeData) {
      updateReservation.mutate(
        {
          id: reservation.id,
          input: {
            insurances: [],
          },
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
      return;
    }

    updateReservation.mutate(
      {
        id: reservation.id,
        input: {
          insurances: [
            {
              insuranceId: selectedInsuranceId,
              fee: insuranceFeeData.price,
              directPaymentPrice: insuranceFeeData.price,
              memo: insuranceFeeData.memo + '(키오스크 가입)',
            },
          ],
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography fontWeight={'bold'} fontSize={40}>
          차량손해면책 선택
        </Typography>
        <Box sx={{ height: '40px' }}></Box>
        {vehicleModelData?.insurances?.map((insurance) => (
          <VehicleInsuranceCard
            key={insurance.id}
            selected={insurance.id === selectedInsuranceId}
            insurance={insurance}
            reservation={reservation}
            onClick={() => {
              if (insurance.id === selectedInsuranceId) {
                setSelectedInsuranceId(0);
                return;
              }
              setSelectedInsuranceId(insurance.id);
            }}
          />
        ))}
        <Box sx={{ height: '12px' }}></Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <GreyButton
            variant='contained'
            fullWidth
            onClick={() => {
              props.onClose();
            }}
          >
            <Typography fontSize={40} fontWeight='medium'>
              취소
            </Typography>
          </GreyButton>
          <Button
            variant='contained'
            fullWidth
            sx={{
              marginLeft: '20px',
            }}
            onClick={handleUpdateInsuranceId}
          >
            <Typography fontSize={40} fontWeight='medium'>
              선택 완료
            </Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default VehicleInsuranceSelectDialog;
