import { CssBaseline, ThemeProvider } from '@mui/material';
import { init } from '@sentry/electron/renderer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { locale } from 'dayjs';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import App from './app/App';
import theme from './app/theme';

locale('ko');

init({
  dsn: 'https://f0eefe64efebc7bb0bedf23bcc7dbe80@o4505696477839360.ingest.sentry.io/4506658912796672',
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // 다른 window 갔다가 focus했을 때 자동으로 refetch 되는 옵션
      retry: 0,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </HashRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);
